<template>
  <div class="container fullBack" v-if="miniSideBarData">
    <b-row>
      <b-col md="7">
        <!-- swiper Gallery -->
        <swiper
          class="swiper-autoplay mt-2"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :key="swiperOptions.slidesPerView"
        >
          <swiper-slide v-for="(data, index) in miniSideBarData" :key="index">
            <div class="image-container">
               <b-img :src="data.image" fluid />
              <div class="container overlay">
                <div>{{ data.title }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </b-col>
      <b-col md="5">
        <contact-vue/>
       </b-col
      >
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Ripple from "vue-ripple-directive";
import contactVue from "./contact.vue";
//import axios from "axios";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    Swiper,
    SwiperSlide,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    contactVue,

  },
  directives: {
    Ripple,
  },
  props: {
    miniSideBarData: Array,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperOptions: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        slidesPerView: 1,
        spaceBetween: 20,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
    };
  },
  

  mounted() {
    if (window.innerWidth <= 1000) {
      this.swiperOptions.slidesPerView = 2;
    } else {
      this.swiperOptions.slidesPerView = 4;
    }
   // console.log(this.miniSideBarData)
  },
 /* created() {
    this.getMiniSideBarData();
  },
  methods: {
    async getMiniSideBarData() {
      await axios
      .get(this.apiUrl + "/api/Contents/GetListByPageAndComponentId?pageId=1&componentId=5")
        .then((result) => {
          if (result.data.success) {
            this.miniSideBarData = result.data.data;
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
  },*/
};
</script>
<style>
.fullBack {
  background-image: url(../../../assets/images/banner/fiftyArkaplan.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.baslik {
  font-family: "Prompt", sans-serif;
  background: transparent;
  box-shadow: none;
  padding: 50px;
}

.image-container {
  position: relative;
  overflow: hidden;
  opacity: 1;
  width: 150%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 20px;
}

.image-container:hover .overlay {
  opacity: 0;
}

.overlay p {
  font-size: 18px;
}

@media (max-width: 767px) {
  .image-container {
    width: 100%;
  }
}
</style>
