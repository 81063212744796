<template>
  <!-- blogs -->
  <b-row class="blog-list" v-if="blogList">
    <b-col md="12">
      <b-card class="fontCard" tag="article" no-body>
        <div class="cardContent">
          <b-row>
            <b-col md="6">
              <b-link>
                <div class="imgContainer">
                  <b-img
                    :src="blogList[0].image"
                    class="b-img"
                    no-body
                    height="450%"
                  />
                </div>
              </b-link>
            </b-col>
            <b-col md="6">
              <b-card-body>
                <b-card-title class="blog-title-truncate text-body-heading">
                  <h1 class="display-5">{{ blogList[0].title }}</h1>
                </b-card-title>
                <div class="altkisim">
                  <b-card-text class="blog-content-truncate excerpt">
                    <div v-html="blogList[0].text"></div>
                  </b-card-text>
                  <div class="d-flex justify-content-between">
                    <b-button
                      @click="redirectToUrunler"
                      variant="gradient-secondary ml-auto mt-auto"
                    >
                      {{ buttonText }}
                    </b-button>
                  </div>
                </div>
              </b-card-body>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
//import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BImg,
    BPagination,
  },
  props: {
    blogList: Array,
  },
  data() {
    return {
      search_query: "",
      perPage: 1,
      rows: 140,
      buttonText: "ÜRÜNLERİMİZ",
    };
  },
  mounted() {
    const language = localStorage.getItem("language");
    if (language && language === "2") {
      this.buttonText = "OUR PRODUCTS"; 
    }
  },
  methods: {
    redirectToUrunler() {
      this.$router.push("/urunler");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
.fontCard {
  font-family: "Raleway", sans-serif;
  margin: 0;
  display: flex;
  position: relative;
  /* Set background color for the entire card */
}

.imgContainer {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -48px -50px;
  background-color: rgb(189, 4, 4);
  clip-path: polygon(0 0, 99% 0, 89% 100%, 0% 100%);
}

.b-img {
  width: 100%;
  object-fit: cover;
  clip-path: polygon(
    0 0,
    99% 0,
    87% 100%,
    0% 100%
  ); /* Use clip-path to achieve the diagonal cut */
}

.cardContent {
  flex: 1;
  padding: 20px;
  background-color: #0725419a;
}

.excerpt {
  font-size: 20px;
  color: white;
}

h1 {
  font-size: 40px;
  color: white;
}

@media (max-width: 767px) {
  h1 {
    font-size: 20px;
  }
  .excerpt {
    font-size: 14px;
  }
  .b-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    clip-path: polygon(
      0 0,
      99% 0,
      87% 100%,
      0% 100%
    ); /* Use clip-path to achieve the diagonal cut */
  }
  .imgContainer {
    margin-left: 0;
    margin: -40px 10px 10px;
    width: 100%;
    background-color: rgb(189, 4, 4);
    clip-path: polygon(0 0, 100% 0, 89% 100%, 0% 100%);
  }
  .cardContent {
    padding: 10px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
