<template>
  <div v-if="swiperData">
  <swiper
    class="swiper-navigations"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >
    <swiper-slide v-for="(data, index) in swiperData" :key="index">
      <b-img :src="data.image" fluid  class="responsive-image" /> 
      <div class="title">
        {{ data.title }}
      </div>
    </swiper-slide>

    <!-- Add Arrows -->
    <div slot="pagination" class="swiper-pagination"></div>
  </swiper></div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";


export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    swiperData: Array,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        effect: "fade",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },

    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@200&display=swap");

.swiper-navigations {
  position: relative;
}

.responsive-image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.title {
  position: absolute;
  bottom: 40%; /* Adjust as needed */
  background-color: #072541d3;
  padding: 40px;
  color: #fff;
  font-size: 28px; /* Adjust the font size as needed */
  font-family: "Prompt", sans-serif;
}

@media (max-width: 1000px) {
  .swiper-navigations {
    position: static;
    margin-top: 0; /* Adjust as needed */
  }

  .swiper-pagination {
    position: static;
    /*bottom: 15% /* Adjust as needed */
  }

  .title {
    position: absolute;
    bottom: 20%; /* Adjust as needed */
    padding: 20px;
    font-size: 20px; /* Adjust the font size as needed */
  }

  .responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
</style>
