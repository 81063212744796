<template>
  <!-- blogs -->
  <b-row class="blog-list" v-if="aboutCardData">
    <b-col md="12">
      <b-card class="fontCard" tag="article" no-body>
        <div class="cardContent">
          <b-row>
            <b-col md="12" class="metin">
              <b-card-title>
                <b-card-title class="blog-title-truncate text-body-heading">
                  <h1 class="display-5">{{ aboutCardData[0].title }}</h1>
                </b-card-title>
              </b-card-title>
              <div class="altkisim">
                
                <b-card-text class="blog-content-truncate excerpt">
                  <div v-html="aboutCardData[0].text"></div>
                </b-card-text>
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="gradient-secondary "
                    class="butonColor mb-4 mt-2"
                    @click="redirectToAbout"
                  >
                  {{buttonText}}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BLink,
  BEmbed,
  BModal,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BEmbed,
    BModal,
  },
  props: {
    aboutCardData: Array,
  },
  data() {
    return {
      search_query: "",
      perPage: 1,
      rows: 140,
      buttonText:"HAKKIMIZDA"
    };
  },
  mounted() {
    const language = localStorage.getItem("language");
    if (language && language === "2") {
      this.buttonText = "ABOUT US"; 
    }
  },
  methods: {
    kFormatter,
    redirectToAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
.fontCard {
  font-family: "Raleway", sans-serif;
  background-image: url(../../../assets/images/banner/fourtyArka.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Resmi yinelenmesini engelle */
  background-attachment: fixed;
  overflow: hidden;
  border-radius: 0;
  position: relative;
}

.metin {
  justify-content: center;
  text-align: center;
  top: 40px;
  padding: 20px;
}

h1 {
  color: white;
}
.excerpt {
  font-size: 20px;
  color: white;
}

@media (max-width: 767px) {
  h1 {
    font-size: 20px;
  }
  .excerpt {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
