<template>
  <b-row>
    <b-col md="1"></b-col>
    <b-col md="10">
      <b-col cols="12">
        <b-card-group class="mb-0" v-if="ReklamData" >
          <!-- card 1 -->
          <b-card no-body class="customBorderTwo">
            <b-card-body>
              <b-card-title class="avatar-container">
                <b-avatar variant="danger" size="70" class="shadow mb-2">
                  <feather-icon size="28" icon="TrendingUpIcon" />
                </b-avatar>
              </b-card-title>

              <b-card-text>
                <div v-html="ReklamData[0].text"></div>
              </b-card-text>
            </b-card-body>
          </b-card>

          <!-- card 2 -->
          <b-card no-body class="customBorder">
            <b-card-body>
              <b-card-title class="avatar-container">
                <b-avatar variant="danger" size="70" class="shadow mb-2">
                  <feather-icon size="28" icon="ToolIcon" />
                </b-avatar>
              </b-card-title>

              <b-card-text>
                <div v-html="reklamDatas"></div>
              </b-card-text>
            </b-card-body>
          </b-card>

          <!-- card 3 -->
          <b-card no-body class="customBorderTwo">
            <b-card-body>
              <b-card-title class="avatar-container">
                <b-avatar variant="danger" size="70" class="shadow mb-2">
                  <feather-icon size="28" icon="AwardIcon" /> </b-avatar
              ></b-card-title>
              <b-card-text>
                <div v-html="ReklamData[2].text"></div>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-col>
    <b-col md="1"></b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardGroup,
  BRow,
  BCol,
  BCardText,
  BCardBody,
  BAvatar,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
//import axios from "@/libs/subu-axios";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BCardBody,
    BCardGroup,
    BAvatar,
    BCardTitle,
  },
  props: {
    ReklamData: Array,
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      goalOverview: {
        completed: "786,617",
        inProgress: "13,561",
        series: [3000],
      },
      reklamDatas: null,
    };
  },

mounted(){
  this.reklamDatas = this.ReklamData[1].text
},
  /* created() {
    this.getAdvertData();
  },
  methods: {
    async getAdvertData() {
      
      await axios
      .get(this.apiUrl + "/api/Contents/GetListByPageAndComponentId?pageId=1&componentId=3")
        .then((response) => {
        if (response.data.success) {
          this.ReklamData = response.data.data
          console.log(this.ReklamData)
        } else {
          console.error("API yanıtında başarısızlık:", response.data.message);
        }
      })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    
  },
}*/
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
.customBorder {
  border: 2px solid rgba(189, 4, 4, 0.774);
}
.customBorderTwo {
  background-color: rgba(189, 4, 4, 0.774);
}
h3 {
  color: white;
  font-family: "Raleway", sans-serif;
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
