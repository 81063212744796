<template>
  <b-card class="contact bg-transparent">
    <b-form @submit.prevent="submitForm">
      <b-row>
        <b-card-text class="formText">
          <h2 style="color: black">{{ pageTitle }}</h2></b-card-text
        >
        <b-col cols="12">
          <b-form-group>
            <b-form-input id="h-first-name" placeholder="Ad" class="input-sm"   v-model="firstName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-input
              id="h-first-name"
              placeholder="Soyad"
              class="input-sm"
              v-model="lastName"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-input
              id="h-email"
              type="email"
              placeholder="Email"
              class="input-sm"
              v-model="email"

            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-input
              id="h-number"
              type="number"
              placeholder="Telefon"
              class="input-sm"
              v-model="phone"

            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-textarea
            id="textarea-default"
            placeholder="MESAJ"
            rows="3"
            class="input-sm"
            v-model="message"

          />
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="gradient-secondary"
            class="mr-1 ml-3 mt-1"
          >
           {{submitBTn}}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardText,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      email:"",
      firstName:"",
      lastName:"",
      phone:"",
      message:"",
      pageTitle:"BİZE ULAŞIN",
      submitBTn:"Gönder"
    };
  },
  mounted() {
    const language = localStorage.getItem("language");
    if (language && language === "2") {
      this.pageTitle = "Contact Us";
      this.submitBTn = "Send";
    }
  },
  methods: {
    submitForm() {
      const newSliderInfo = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        message: this.message
      };
      axios
        .post("/api/Communications/Create", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("Mesajınız Gönderildi", {
              title: `Form`,
              variant: "success",
            });
            this.email = "";
            this.firstName = "";
            this.lastName = "";
            this.phone = "";
            this.message = "";
          } else {
            this.$bvToast.toast("Mesajınız Gönderilemedi!", {
              title: `Form`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
  },
};
</script>
<style>
.input-sm {
  width: 50%;
  margin-left: 135px;
}
.formText {
  margin-left: 150px;
}
.contact {
  box-shadow: none;
}
@media (max-width: 767px) {
  .formText {
    margin-left: 50px;
  }
  .input-sm {
    width: 80%;
    margin-left: 25px;
  }
  .image-container {
    width: 100%;
  }
}
</style>
